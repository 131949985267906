<template>
  <div class="controllerQiangjiu">
    <div class="controller-modules-header left">复苏</div>
    <div class="radio-controller">
      <div
        class="item"
        v-for="(item, index) in fusuList"
        :key="index"
        :class="fusu == item.type ? 'item-active' : ''"
        @click="handlerRadio('fusu', item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="controller-modules-header left">除颤器</div>
    <div class="selectForm">
      <div class="item left">
        <h4>活力</h4>
        <a-select v-model:value="selectValue" placeholder="请选择">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" v-for="(item,index) in chuchanList" :key="index">{{item}}</a-select-option>
        </a-select>
        焦耳
      </div>
      <div class="check right">
        <a-checkbox v-model:checked="checkValue">同步</a-checkbox>
      </div>
    </div>
    <button class="controller-btn no-margin-top" @click="submit">
      全清！释放！
    </button>

    <div class="controller-modules-header left">操作记录</div>
    <div class="controller-modules-log">
      <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { fusuList,chuchanList } from '@/utils/data';
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      fusuList,
      chuchanList,
      fusu: "",
      selectValue: undefined,
      checkValue: false,
      formateSeconds,
      actionLog:[],
    };
  },
  created(){
    this.getActionLog();
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
    }),
    submit() {
      if(!this.selectValue)return;
      let d = {
        memberCaseId: this.memberCaseId,
        component: 'all_clear',
        actionTime: this.getHandleTimeAt,
        otherData: {
          huoli:this.selectValue,
          tongbu:this.checkValue
        },
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
      });
    },
    handlerRadio(model, type) {
      this[model] = type || "";
      let d = {
        memberCaseId: this.memberCaseId,
        component: type,
        actionTime: this.getHandleTimeAt,
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
      });
      
    },
    async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'resuscitate',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    }
  },
  computed: {
    ...mapGetters(["getHandleTimeAt", "memberCaseId"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>